import atob from "atob";
import ls from "local-storage";

import { removeCookie } from "./cookies";

import client from "api/live/client";
import { LOCALSTORAGE_TOKEN_KEY } from "constants/base";
import { prodLog } from "utils/dev";

export const setToken = (token) => {
  ls(LOCALSTORAGE_TOKEN_KEY, token);
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const getToken = () => ls(LOCALSTORAGE_TOKEN_KEY);

export const tokenExpired = (token) => {
  if (!token) {
    return true;
  }

  try {
    const obj = JSON.parse(atob(token.split(".")[1]));
    const now = Math.floor(new Date().getTime() / 1000);
    return now >= obj.exp && now >= obj.nbf;
  } catch (e) {
    prodLog("tokenExpired catch", e);

    return false;
  }
};

export const clearToken = () => {
  removeCookie(LOCALSTORAGE_TOKEN_KEY, { path: "/", secure: true });
  removeCookie(LOCALSTORAGE_TOKEN_KEY, { path: "/", secure: false });
  ls.remove(LOCALSTORAGE_TOKEN_KEY);
  delete client.defaults.headers.common.Authorization;
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
};
