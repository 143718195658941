import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PLFP2NS",
};

const GoogleTagManagerScript = () => {
  TagManager.initialize(tagManagerArgs);

  return null;
};

export default GoogleTagManagerScript;
