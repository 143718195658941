export const LOGIN_REGISTER_FORM_SUBMIT = "LOGIN_REGISTER_FORM_SUBMIT";
export const LOGIN_REGISTER_FORM_SUCCESS = "LOGIN_REGISTER_FORM_SUCCESS";
export const LOGIN_REGISTER_FORM_FAILURE = "LOGIN_REGISTER_FORM_FAILURE";
export const NO_TOKEN_AT_INIT = "NO_TOKEN_AT_INIT";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SUCCESS_TEST = "LOGIN_SUCCESS_TEST";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const TRIGGER_LOGIN_SUCCESS = "TRIGGER_LOGIN_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_UPDATE = "LOGOUT_UPDATE";
export const LOGIN_CANCELLED = "LOGIN_CANCELLED";
export const START_AUTH_FLOW = "START_AUTH_FLOW";
export const START_SOCIAL_LOGIN_FLOW = "START_SOCIAL_LOGIN_FLOW";
export const START_SOCIAL_CONNECT = "START_SOCIAL_CONNECT";
export const SOCIAL_CONNECT_DENIED = "SOCIAL_CONNECT_DENIED";
export const SOCIAL_AUTH_LOADING = "SOCIAL_AUTH_LOADING";
export const SOCIAL_AUTH_LOADED = "SOCIAL_AUTH_LOADED";
export const SOCIAL_RETURN = "SOCIAL_RETURN";
export const FACEBOOK_LOGGED_IN = "FACEBOOK_LOGGED_IN";
export const GOOGLE_ONE_TAP_LOGIN = "GOOGLE_ONE_TAP_LOGIN";
export const SOCIAL_AUTH_PASSWORD_REQUIRED = "SOCIAL_AUTH_PASSWORD_REQUIRED";
export const SOCIAL_AUTH_PASSWORD_SUBMIT = "SOCIAL_AUTH_PASSWORD_SUBMIT";
export const SOCIAL_AUTH_PASSWORD_INCORRECT = "SOCIAL_AUTH_PASSWORD_INCORRECT";
export const SOCIAL_AUTH_PASSWORD_SUPPLIED = "SOCIAL_AUTH_PASSWORD_SUPPLIED";
export const SOCIAL_AUTH_USERNAME_SUPPLIED = "SOCIAL_AUTH_USERNAME_SUPPLIED";
export const SOCIAL_AUTH_USERNAME_REQUIRED = "SOCIAL_AUTH_USERNAME_REQUIRED";
export const SOCIAL_AUTH_USERNAME_SUBMIT = "SOCIAL_AUTH_USERNAME_SUBMIT";
export const SOCIAL_AUTH_USERNAME_ALREADY_EXISTS =
  "SOCIAL_AUTH_USERNAME_ALREADY_EXISTS";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const RESUME_OAUTH_PROCESS = "RESUME_OAUTH_PROCESS";
export const REQUEST_PASSWORD_RESET_SUCCESS = "REQUEST_PASSWORD_RESET_SUCCESS";
export const SET_AUTH_STATE = "SET_AUTH_STATE";

export const TOKEN_LOAD_REQUEST = "TOKEN_LOAD_REQUEST";
export const LOAD_USER_ENTITY_DATA_SUCCESS = "LOAD_USER_ENTITY_DATA_SUCCESS";
export const ADDITIONAL_NEW_USER_DETAILS_REQUIRED =
  "ADDITIONAL_NEW_USER_DETAILS_REQUIRED";
export const NEW_OAUTH_CONTEXT = "NEW_OAUTH_CONTEXT";
