import { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function useQuery() {
  const { search } = useLocation();
  const history = useHistory();

  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  function setQuery(newQuery) {
    history.replace({
      search: "?" + new URLSearchParams(newQuery).toString(),
    });
  }

  const query = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    const entries = urlParams.entries();
    return paramsToObject(entries);
  }, [search]);

  return [query, setQuery];
}
