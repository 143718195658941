import PropTypes from "prop-types";
import {
  createContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";

import { selectUserHasPermission } from "selectors/auth";
import { getCookie, setCookie, removeCookie } from "utils/cookies";
import { CAN_MODERATE_PERMISSION } from "utils/entity/user";

import useReduxState from "hooks/useReduxState";

export const UseIsModeratorContext = createContext();

const COOKIE_NAME = "PodchaserModeratorMode";

function setModerationCookie() {
  const oneYearExpiries = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );

  setCookie(COOKIE_NAME, true, {
    expires: oneYearExpiries,
    path: "/",
  });
}

export function getModeratorModeCookie() {
  const hasCookieSettled = getCookie(COOKIE_NAME);
  return !!hasCookieSettled;
}

const UseIsModeratorProvider = (props) => {
  const { children } = props;

  const [isModerator, setIsModerator] = useState(false);

  const { canModerate } = useReduxState((state) => {
    return {
      canModerate: selectUserHasPermission(state, CAN_MODERATE_PERMISSION),
    };
  }, []);

  useEffect(() => {
    const moderatorModeCookie = getModeratorModeCookie();

    if (canModerate) {
      setIsModerator(moderatorModeCookie);
    }
  }, [canModerate]);

  useEffect(() => {
    if (isModerator) {
      const moderatorModeCookie = getModeratorModeCookie();

      if (!moderatorModeCookie) {
        setModerationCookie();
      }
    } else {
      removeCookie(COOKIE_NAME, { path: "/" });
    }
  }, [isModerator]);

  const toggleModeratingStatus = useCallback(() => {
    setIsModerator((isModerator) => !isModerator);
  }, []);

  const turnOffModerator = useCallback(() => {
    setIsModerator(false);
    removeCookie(COOKIE_NAME, { path: "/" });
  }, []);

  const contextState = useMemo(
    () => ({
      isModerator,
      toggleModeratingStatus,
      turnOffModerator,
    }),
    [isModerator, toggleModeratingStatus, turnOffModerator]
  );

  return (
    <UseIsModeratorContext.Provider value={contextState}>
      {children}
    </UseIsModeratorContext.Provider>
  );
};

UseIsModeratorProvider.propTypes = {
  children: PropTypes.node,
};

UseIsModeratorProvider.defaultProps = {
  children: null,
};

export default UseIsModeratorProvider;
