import ls from "local-storage";
import { useCallback, useEffect, useState } from "react";

import { removeCookie, setCookie } from "utils/cookies";

// modified from https://github.com/rehooks/local-storage to remove TypeScript as it was erroring on CustomEvent

function dispatchLocalStorageEvent(key, value) {
  if (typeof window !== "undefined") {
    const e = new CustomEvent("onLocalStorageChange");
    e.key = key;
    e.newValue = value;
    window.dispatchEvent(e);
  }
}

/**
 * Use this instead of directly using localStorage.setItem
 * in order to correctly send events within the same window.
 *
 * @example
 * ```js
 * writeStorage('hello', JSON.stringify({ name: 'world' }));
 * const { name } = JSON.parse(localStorage.getItem('hello'));
 * ```
 *
 * @export
 * @param {string} key The key to write to in the localStorage.
 * @param {string} value The value to write to in the localStorage.
 * @param withCookie
 * @param cookieDomain
 */
export function writeStorage(key, value, { withCookie }) {
  ls(key, value);
  if (withCookie) {
    setCookie(key, value);
  }
  dispatchLocalStorageEvent(key, value);
}

/**
 * Use this function to delete a value from localStorage.
 *
 * @example
 * ```js
 * const user = { name: 'John', email: 'John@fakemail.com' };
 *
 * // Add a user to your localStorage
 * writeStorage('user', JSON.stringify(user));
 *
 * // This will also trigger an update to the state of your component
 * deleteFromStorage('user');
 * ```
 *
 * @export
 * @param {string} key The key of the item you wish to delete from localStorage.
 * @param {bool} withCookie Whether or not to also delete the cookie
 */
export function deleteFromStorage(key, { withCookie = false }) {
  localStorage.removeItem(key);
  if (withCookie) {
    removeCookie(key);
  }
  dispatchLocalStorageEvent({ key, value: "" });
}

/**
 * React hook to enable updates to state via localStorage.
 * This updates when the {writeStorage} function is used, when the returned function
 * is called, or when the "storage" event is fired from another tab in the browser.
 *
 * @example
 * ```js
 * const MyComponent = () => {
 *   const [myStoredItem, setMyStoredItem] = useLocalStorage('myStoredItem');
 *   return (
 *     <p>{myStoredItem}</p>
 *   );
 * };
 * ```
 *
 * @export
 * @param {string} key The key in the localStorage that you wish to watch.
 * @returns An array containing the value associated with the key in position 0,
 * and a function to set the value in position 1.
 */
export function useLocalStorage(key, options = {}, defaultState) {
  const [localState, updateLocalState] = useState(ls(key) || defaultState);

  const onLocalStorageChange = useCallback(
    (event) => {
      if (event.key === key) {
        if (
          typeof event.newValue === "string" &&
          event.newValue.substr(event.newValue.length - 1) === '"' &&
          event.newValue.substr(0, 1) === '"'
        ) {
          // updated string values are sometimes placed in extra doublequotes
          // so we need to remove them before its used
          const stringWithoutExtraCommas = event.newValue.substr(
            1,
            event.newValue.length - 2
          );

          updateLocalState(stringWithoutExtraCommas);
        } else {
          updateLocalState(event.newValue);
        }
      }
    },
    [key]
  );

  useEffect(() => {
    window.addEventListener("onLocalStorageChange", (e) =>
      onLocalStorageChange(e)
    );
    window.addEventListener("storage", (e) => onLocalStorageChange(e));

    return () => {
      window.removeEventListener("onLocalStorageChange", (e) =>
        onLocalStorageChange(e)
      );
      window.removeEventListener("storage", (e) => onLocalStorageChange(e));
    };
  }, [onLocalStorageChange]);

  const writeToStorage = useCallback(
    (value) => writeStorage(key, value, options),
    [key, options]
  );

  const deleteStorage = useCallback(() => {
    deleteFromStorage(key, options);
    updateLocalState(undefined);
  }, [key, options]);

  return [localState, writeToStorage, deleteStorage];
}

export default useLocalStorage;
