import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const CreatorsIndex = Loadable(() => import("./CreatorsIndex"));

function CreatorsIndexAsyncLoading(props) {
  return <LoadableLoading {...props} transparent page />;
}

const CreatorsIndexContainer = (props) => {
  return <CreatorsIndex {...props} fallback={<CreatorsIndexAsyncLoading />} />;
};

export default CreatorsIndexContainer;
