import { memo } from "react";

import HeaderNavWrapper from "components/Layout/Header/HeaderNavWrapper";
import TopLevelRouteStructure from "pages/Common/TopLevelRouteStructure";

import DashboardSkeleton from "./DashboardSkeleton";

import ScreenSizes from "styles/ScreenSizes";

const TOP_LEVEL_ROUTE_STYLES = {
  topWrapperBody: {
    paddingTop: "1.25rem",

    [ScreenSizes.lgAndAbove]: {
      paddingTop: "8.25rem",
    },
  },
};

const HomepageViewSkeleton = (props) => {
  return (
    <TopLevelRouteStructure
      header={
        <HeaderNavWrapper
          withHeader={props.withHeader}
          withNavMenu
          isHomepage
        />
      }
      styles={TOP_LEVEL_ROUTE_STYLES}
    >
      <DashboardSkeleton />
    </TopLevelRouteStructure>
  );
};

export default memo(HomepageViewSkeleton);
