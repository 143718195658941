import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Networks = Loadable(() => import("./Networks"));

function NetworksAsyncLoading(props) {
  return (
    <LoadableLoading
      key="topLevelRouteLoadingOverlay"
      withPageHeader
      height={"136rem"}
    />
  );
}

const NetworksContainer = (props) => {
  return <Networks {...props} fallback={<NetworksAsyncLoading />} />;
};

export default NetworksContainer;
