import { useCallback } from "react";

import SelectSearchDropDown from "components/Common/FilterSelects/SelectSearchDropDown";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  category: {
    border: "none",
    outline: "none",
    ...gStyles.fontMedium,
    fontSize: "0.75rem",
    minHeight: "1.5rem",
    textAlign: "start",

    ":hover": {
      cursor: "pointer",
    },
  },
  active: {
    ...gStyles.fontBold,
    color: colours.darkishBlue,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 150,
    overflow: "auto",
  },
};

export default function SearchSingleSelectFilter(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const {
    filter,
    selected,
    onChange,
    label,
    topLabel,
    ariaLabel,
    defaultSelectedId,
  } = props;

  const handleClick = useCallback(
    (value, closer) => {
      onChange && onChange(value);
      closer && closer();
    },
    [onChange]
  );

  const renderList = useCallback(
    (data, props) => {
      const { toggleButton } = props;
      return (
        <div className={css(styles.listContainer)}>
          {data?.map((option) => {
            const item =
              option?.get("text") ||
              option?.get("label") ||
              option?.get("value");
            const active =
              selected?.toLowerCase() === option?.get("value")?.toLowerCase() ||
              selected?.toLowerCase() === option?.get("id")?.toLowerCase();

            return (
              <button
                key={option?.get("id")}
                onClick={() => handleClick(option?.get("id"), toggleButton)}
                className={css(styles.category, active && styles.active)}
              >
                {item}
              </button>
            );
          })}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClick, selected]
  );

  return (
    <SelectSearchDropDown
      selected={selected}
      filter={filter}
      topLabel={topLabel}
      ariaLabel={ariaLabel}
      label={label}
      renderList={renderList}
      defaultSelectedId={defaultSelectedId}
    />
  );
}
