import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Episodes = Loadable(() => import("./Episodes"));

function EpisodesAsyncLoading(props) {
  return (
    <LoadableLoading
      key="topLevelRouteLoadingOverlay"
      withPageHeader
      height={"337rem"}
    />
  );
}

const EpisodesContainer = (props) => {
  return <Episodes {...props} fallback={<EpisodesAsyncLoading />} />;
};

export default EpisodesContainer;
