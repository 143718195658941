import axios from "axios";

import configGet, { configHas } from "../../../config";

import { LOCALSTORAGE_TOKEN_KEY } from "constants/base";
import { getToken, setToken, tokenExpired, clearToken } from "utils/auth";
import { setCookie, removeCookie } from "utils/cookies";

try {
  axios.defaults.baseURL = configHas("api.serverBaseUrl")
    ? configGet("api.serverBaseUrl")
    : configGet("api.clientBaseUrl");
} catch (e) {
  // likely running via storybook
  console.warn(e);
}

let instance;

if (typeof window === "undefined") {
  // we're on the server, so pass a custom http and https agent with keepalive turned on
  const http = require("http");
  const https = require("https");

  const httpAgent = new http.Agent({ keepAlive: true });
  const httpsAgent = new https.Agent({ keepAlive: true });

  instance = axios.create({
    httpAgent,
    httpsAgent,
  });
} else {
  instance = axios.create();
}

function getRefreshToken(config, token) {
  let requestConfig;
  const isServer = typeof window === "undefined";
  if (isServer) {
    // we're not logging in on the server anymore, so should never need a refresh token
    return new Promise(resolve => resolve({}))
  }
  // only start a new refresh token request if there's not already one active
  if (!window.refreshTokenRequest) {
    window.refreshTokenRequest = axios.get("/auth/refresh", {
      headers: { Authorization: "Bearer " + token },
    });
  }

  return window.refreshTokenRequest;
}
instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = getToken();
    if (!token) {
      return config;
    }
    const expired = tokenExpired(token);
    const isClient = typeof window !== "undefined";

    if (expired && !config.url.match(/auth\/refresh\/?$/)) {
      return getRefreshToken(config, token)
        .then((response) => {
        const newToken = response?.data?.token;

        if (!newToken)
        {
          removeCookie(LOCALSTORAGE_TOKEN_KEY);
          clearToken();
          if (isClient)
          {
            window.refreshTokenRequest = null;
          }
          return config;
        }

          console.log("New token set");

          config.headers.common.Authorization = `Bearer ${newToken}`;
          setCookie(LOCALSTORAGE_TOKEN_KEY, newToken, {
            path: "/",
            maxAge: 7 * 24 * 60 * 60,
          });
          if (isClient) {
            window.refreshTokenRequest = null;
          }
          setToken(newToken);
          return config;
        })
        .catch(function (e) {
          if (isClient) {
            removeCookie(LOCALSTORAGE_TOKEN_KEY);
            clearToken();
            window.refreshTokenRequest = null;
          }
          return config;
        });
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
