import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import shortid from "shortid";

import Context from "./NotificationsContext";
import NotificationsList from "./NotificationsListAsync";
import NotificationsPlacement from "./NotificationsPlacement";

const NotificationsProvider = (props) => {
  const {
    children,
    placement,
    horizontal,
    dark,
    timeout,
    useNotificationTextAsID,
  } = props;
  const timeouts = useRef({});
  const [notifications, setNotifications] = useState({});

  const onTimeout = useCallback(
    (key) => () => {
      setNotifications((prevNotifictions) =>
        Object.entries(prevNotifictions).reduce(
          (newNotifications, [notKey, notValue]) => {
            if (notKey !== key) {
              return {
                ...newNotifications,
                [notKey]: notValue,
              };
            }

            return newNotifications;
          },
          {}
        )
      );

      delete timeouts.current[key];
    },
    []
  );

  const setNotificationTimeout = useCallback(
    (key) => setTimeout(onTimeout(key), timeout),
    [timeout, onTimeout]
  );

  const addNotification = useCallback(
    (content) => {
      const key = useNotificationTextAsID ? content : shortid.generate(); // useNotificationTextAsID for testing

      setNotifications((prevNotifications) => ({
        ...prevNotifications,
        [key]: {
          key,
          content,
        },
      }));

      if (timeout > 0) {
        timeouts.current = {
          ...timeouts.current,
          [key]: setNotificationTimeout(key),
        };
      }
    },
    [timeouts, timeout, setNotificationTimeout, useNotificationTextAsID]
  );

  const removeNotification = useCallback((notification) => {
    delete timeouts.current[notification.key];

    setNotifications((prevNotifictions) =>
      Object.entries(prevNotifictions).reduce(
        (newNotifications, [key, value]) => {
          if (notification.key !== value.key) {
            return {
              ...newNotifications,
              [key]: value,
            };
          }

          return newNotifications;
        },
        {}
      )
    );
  }, []);

  const contextState = useMemo(
    () => ({
      notifications,
      addNotification,
    }),
    [addNotification, notifications]
  );
  const sortedNotifications = useMemo(
    () => Object.values(notifications).sort((a, b) => a.key > b.key),
    [notifications]
  );

  return (
    <Context.Provider value={contextState}>
      <Fragment>
        {children}
        {sortedNotifications && sortedNotifications.length > 0 && (
          <NotificationsPlacement placement={placement}>
            <NotificationsList
              horizontal={horizontal}
              dark={dark}
              notifications={sortedNotifications}
              onClose={removeNotification}
            />
          </NotificationsPlacement>
        )}
      </Fragment>
    </Context.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node,
  timeout: PropTypes.number,
  placement: PropTypes.string,
  horizontal: PropTypes.bool,
  dark: PropTypes.bool,
  useNotificationTextAsID: PropTypes.bool,
};

NotificationsProvider.defaultProps = {
  children: null,
  timeout: 0,
  placement: "bottom-right",
  horizontal: false,
  dark: false,
  useNotificationTextAsID: false,
};

export default NotificationsProvider;
