import { List } from "immutable";
import mixpanel from "mixpanel-browser";

import client from "api/live/client";
import { toCamelCase } from "utils/case";
import { log } from "utils/dev";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import { userHasProPermission } from "utils/entity/user";

function getUserProperties(loggedInUser, badge) {
  const userData = {
    hasClaimedPodcasts: loggedInUser.get("has_claimed_podcasts"),
    setHasPodcast: loggedInUser.get("has_podcast"),
    username: loggedInUser.get("username"),
    displayName: getUserDisplayName(loggedInUser),
    hasProfilePic: !!loggedInUser.get("profile_image_url"),
    permissions: {},
    badge: null,
  };

  loggedInUser.get("permissions", List()).forEach((permission) => {
    userData.permissions[toCamelCase(permission)] = true;
  });

  loggedInUser.get("roles", List()).forEach((role) => {
    userData.permissions[toCamelCase(role)] = true;
  });

  if (badge && badge.has("title")) {
    userData.badge = {
      title: badge.get("title"),
      id: badge.get("id"),
      code: badge.get("code"),
      date_earned: badge.get("date_earned"),
    };
  }
  return userData;
}

export const setUser = (loggedInUser, badge) => {
  if (!window || typeof window === "undefined") {
    return;
  }

  if (!loggedInUser || !loggedInUser.has("id")) {
    return;
  }

  if (mixpanel) {
    mixpanel.identify(loggedInUser.get("id"));
    mixpanel.people.set({
      ...getUserProperties(loggedInUser, badge),
      $email: loggedInUser.get("email"),
      $name: loggedInUser.get("username"),
      $distinct_id: loggedInUser.get("id"),
    });
  }

  if (window.ga) {
    log("GA setting user ID", loggedInUser);
    window.ga("set", "user_properties", {
      userId: loggedInUser.get("id"),
      proPaidUser: userHasProPermission(loggedInUser),
    });
  }

  if (window.FS && loggedInUser) {
    if (window.FS.identify) {
      window.FS.identify(loggedInUser.get("id"), {
        displayName: loggedInUser.get("username"),
        email: loggedInUser.get("email"),
      });
    }
  }

  // set dataLayer variables that Tag Manager can use
  if (window.dataLayer) {
    window.dataLayer.push({
      userUsername: loggedInUser.get("username"),
      userID: loggedInUser.get("id"),
      userAvatarUrl: loggedInUser.get("profile_image_url"),
      userEmail: loggedInUser.get("email"),
      intercomUserHash: loggedInUser.get("intercom_user_hash"),
      proPaidUser: userHasProPermission(loggedInUser),
    });
    window.dataLayer.push({ event: "logged-in-user-updated" });
  }
};

export const setLoggedOut = () => {
  // unset any user properties
  if (window.dataLayer) {
    window.dataLayer.push({
      userUsername: undefined,
      userID: undefined,
      userEmail: undefined,
      userAvatarUrl: undefined,
      intercomUserHash: undefined,
      proPaidUser: undefined,
    });
    window.dataLayer.push({ event: "logged-in-user-updated" });
  }

  if (window.FS?.identify) {
    window.FS.identify(false);
  }

  if (mixpanel) {
    mixpanel.reset();
  }

  if (window.ga) {
    window.ga("set", "userId", undefined);
  }
};

export const sendPCEvent = ({ action, ...params }) => {
  client.post("/analytics/track", {
    action,
    params,
  });
};

export const sendPageView = ({ location }) => {
  if (typeof window !== "undefined" && window.ga) {
    window.ga("send", "pageview", location.pathname);
  }
};
