import { Map } from "immutable";
import { createSelector } from "reselect";
import { List } from "immutable";

export const selectPodcastChartsData = (state, id) => {
  return state.podcast?.getIn([
    "podcasts",
    "entities",
    id?.toString(),
    "charts",
  ]);
};

export const selectPodcastChartsMeta = (state, id) =>
  state.podcast?.getIn(["podcasts", "entities", id?.toString(), "meta"]);

export const selectPodcastChartsLoading = (state, id) =>
  state.podcast?.getIn(["ranksLoading", id?.toString()]) || false;

export const selectPodcastChartHistory = (state, id, rankId) => {
  return (
    state.podcast?.getIn([
      "podcasts",
      "entities",
      id?.toString(),
      "chartHistory",
      rankId?.toString(),
      "ranks",
    ]) || Map()
  );
};

// Selector for chartHistoryLoaded
export const selectPodcastChartHistoryLoaded = (state, id, rankId) =>
  state.podcast?.getIn([
    "podcasts",
    "entities",
    id?.toString(),
    "chartHistoryLoaded",
    rankId?.toString(),
  ]) || false;

export const selectApplePodcasts = (state) =>
  state.chart.getIn(["charts", "applePodcasts"]) || Map();

export const selectSpotifyPodcasts = (state) =>
  state.chart.getIn(["charts", "spotifyPodcasts"]) || Map();

export const selectChartsLoading = (state) =>
  state.chart.getIn(["charts", "loading"]);

export const selectChartsFailed = (state) =>
  state.chart.getIn(["charts", "failed"]);

const selectFilters = (state) => state.chart.getIn(["charts", "filters"]) || [];
const selectSpecificFilter = createSelector(
  [selectFilters, (_, key) => key],
  (filters, key) =>
    List.isList(filters?.get(key))
      ? filters?.get(key)?.toJS()
      : filters?.get(key)
);

const selectLodingFilters = (state) => selectSpecificFilter(state, "loading");
export const makeSelectLodingFilters = () => selectLodingFilters;

const selectFailedFilters = (state) => selectSpecificFilter(state, "failed");
export const makeSelectFailedFilters = () => selectFailedFilters;

const selectPlatformFilters = (state) =>
  selectSpecificFilter(state, "platforms");
export const makeSelectPlatformFilters = () => selectPlatformFilters;

const selectAppleCategoriesFilters = (state) =>
  selectSpecificFilter(state, "appleCategories");
export const makeSelectAppleCategoriesFilters = () =>
  selectAppleCategoriesFilters;

const selectAppleCountriesFilters = (state) =>
  selectSpecificFilter(state, "appleCountries");
export const makeSelectAppleCountriesFilters = () =>
  selectAppleCountriesFilters;

const selectSpotifyCategoriesFilters = (state) =>
  selectSpecificFilter(state, "spotifyCategories");
export const makeSelectSpotifyCategoriesFilters = () =>
  selectSpotifyCategoriesFilters;

const selectSpotifyCountryFilters = (state) =>
  selectSpecificFilter(state, "spotifyCountries");
export const makeSelectSpotifyCountryFilters = () =>
  selectSpotifyCountryFilters;

const selectSpecificAppleCountry = createSelector(
  [
    selectAppleCountriesFilters,
    selectSpotifyCountryFilters,
    (_, countryId) => countryId,
  ],
  (appleCountries, spotifyCountries, countryId) => {
    const combinedCountries = [...appleCountries, ...spotifyCountries];
    let country = {};
    combinedCountries?.every((item) => {
      if (item?.id === countryId) {
        country = item;
        return false;
      }
      return true;
    });
    return country;
  }
);

const selectSpecificCategory = createSelector(
  [
    selectAppleCategoriesFilters,
    selectSpotifyCategoriesFilters,
    (_, categoryId) => categoryId,
  ],
  (appleCategories, spotifyCategories, categoryId) => {
    const combinedCategories = [...appleCategories, ...spotifyCategories];
    let category = {};
    combinedCategories?.every((item) => {
      if (item?.id?.toLowerCase() === categoryId?.toLowerCase()) {
        category = item;
        return false;
      }
      return true;
    });
    return category;
  }
);

const selectSpecificPlatform = createSelector(
  [selectPlatformFilters, (_, platformId) => platformId],
  (platforms, platformId) => {
    let platform = {};
    platforms?.every((item) => {
      if (item?.id === platformId) {
        platform = item;
        return false;
      }
      return true;
    });
    return platform;
  }
);
export const makeSelectSpecificAppleCountryMemoized = () =>
  selectSpecificAppleCountry;
export const makeSelectSpecificPlatformMemoized = () => selectSpecificPlatform;
export const makeSelectSpecificCategoryMemoized = () => selectSpecificCategory;
