// Note: When replacing colours, use this regex to find non-grey colours in files: #([a-fA-F0-9]{2})(?!\1)([a-fA-F0-9]{4})\b

export const rateColours = {
  one: { r: 250, g: 5, b: 104 }, // red
  two: { r: 250, g: 5, b: 104 }, // red
  three: { r: 250, g: 5, b: 104 }, // yellow
  four: { r: 250, g: 5, b: 104 }, // green
  five: { r: 250, g: 5, b: 104 }, // green
};

const baseColours = {
  oldSecondary: "#293b65",
  tertiary: "#694694",
  inProgress: "#fac267",
  primary: "var(--color-primary)",
  secondary: "var(--color-secondary)",
  positive: "var(--color-positive)",
  negative: "var(--color-negative)",
  accent: "var(--color-accent)",
  actioned: "var(--color-actioned)",
  selected: "var(--color-selected)",
};

const newBaseColours = {
  secondaryHighlight2: "#a06bf5",
  fadedWhiteOnNewSecondary: "#c6b3c8",
  newSecondaryD1: "var(--color-secondary-d1)",

  // TODO: Check through these
  newDarkBlue: "#404957",
  newDarkGrey: "#9098a4",
  newDarkRed: "#e96767",
  newGreen: "#34a83b",
  newGrey: "#F4F4F4",
  newGreyBox: "#f1f2f4",
  newLightGreyBox: "#e3e3e3",
  newLightGrey: "#f6f7f9",
  newLightRed: "#ffeeee",
  newMidDarkGrey: "#e7e7e7",
  newPrimaryOpaqued: "#f7056780",
  newYellow: "#fff06f",

  inProgress: "#fac267",
};

// using color package
// lighter lighten(0.3)
// highlight lighten(0.1)
// inset darken(0.1)
// lightDark darken(0.15)
// midDark darken(0.22)
// darker darken(0.3)
const variationColours = {
  neutralL1: "var(--color-neutral-l1)",
  neutralL2: "var(--color-neutral-l2)",
  neutralL3: "var(--color-neutral-l3)",
  neutralL4: "var(--color-neutral-l4)",
  neutralL5: "var(--color-neutral-l5)",

  primaryLighter: "var(--color-primary-highlight)",
  primaryHighlight: "var(--color-primary-highlight)",
  primaryInset: "var(--color-primary-lowlight)",
  primaryDarker: "var(--color-primary-lowlight)",
  primaryTextBackground: baseColours.primary,
  primaryFaded: "#ff669c",
  primaryButton: "#ff0065",

  validLighter: "#DBECB2",
  validFocusBorder: "#9dbb4d",
  validHighlight: "#C4DF7F",
  validInset: "#ACD34B",
  validDarker: "#8cb32c",

  actionedBoxShadow: "var(--box-shadow-actioned)",
  actionedSubtleBoxShadow: "var(--box-shadow-actioned-subtle)",
  actionedHighlight: "var(--color-actioned-highlight)",
  actionedLowlight: "var(--color-actioned-lowlight)",

  actionedForeground: "#fff", // text on top of actioned button

  positiveLighter: "#DBECB2",
  positiveFocusBorder: "#9dbb4d",
  positiveHighlight: "#C4DF7F",
  positiveInset: "#ACD34B",
  positiveDarker: "#8cb32c",
  secondaryPurple: "#5e2597",

  negativeHighlight: "var(--color-negative-highlight)",
  negativeLowlight: "var(--color-negative-lowlight)",

  subtleBodyText: "rgb(70, 80, 83)",
};

const deprecatedColours = {
  tertiary: "var(--color-secondary-d6)",

  tertiaryLighter: "#8155a8",
  tertiaryInset: "#5c3d87",
  tertiaryLightDark: "#4f3579",
  tertiaryMidDark: "#3f2c6c",
  tertiaryDarker: "#32235a",

  tertiaryDesaturated: "#58476c",

  negativeLighter: "#df8384",
  negativeLightDark: "#d96d6e",

  secondaryLighter: "#354D83",
  secondaryHighlight: "#2D416F",
  secondaryInset: "#25355B",
  secondaryLightDark: "#1D2B4D",
  secondaryMidDark: "#1B2747",
  secondaryDarker: "#1D2947",

  newGreyishPurple: "#DAABCF",
  neutral: "#f7f6f9",

  declineRed: "#e54d4d",
  acceptGreen: "#309c37",
  black: "#000",
  white: "#fff",
  offWhite: "#f4f4f4",
  offWhiteLighter: "#f9f9f9",

  lightGreyishBlue: "#e3e5eb",
  semiDarkBlueGrey: "#6b7793",
  greyishBlue: "var(--color-neutral-d8)",
  greyishBlueDarker: "var(--color-neutral-d6)",
  greyishBlueDarkerFont: "var(--color-neutral-d5)", // "#707785",
  greyishBlueHighlight: "var(--color-neutral-d85)",
  silver: "var(--color-neutral-d7)",
  lightGrey: "var(--color-neutral-l2)",
  lightishGrey: "var(--color-neutral-d7)",
  midGrey: "var(--color-neutral-d5)",
  grey: "var(--color-neutral-d4)",
  gunMetal: "var(--color-neutral-d2)",
  transcriptBorder: "var(--color-neutral-d95)",

  selectedLightBlue: "var(--color-selected-d95)",
  selectedSemiLightBlue: "var(--color-selected-d8)",
  selectedDarkBlue: "var(--color-selected-d5)",

  brightIconGrey: "#e8eaf8",
  placeholderText: "#707785",
  blueGreyButton: "#8b90b5",
  blueGrey: "#707D9A",
  blueButton: "#439ad0",

  buttonBorderGrey: "#DFE1E6",
  borderGrey: "var(--color-neutral-d95)",
  deepGrey: "var(--color-neutral-d1)",
  greyDot: "#b3abba",
  greyBorder: "#ebebeb",

  lightGreyBorder: "#e9e9e9",
  lightishGreyBorder: "#e5e5e5",
  midGreyBorder: "#dbdbdb",
  greyBackground: "#fcfcfc",
  diabledLightGrey: "#e8e8e8",
  placeHolder: "#787878",

  highlight: "#fff176",
  adRatePillBackground: "#F0F5FF",
  adRatePillborder: "#CACACA",
  disclaimer: "#747474",
  purpleishPlaceholder: "b19cc4",
  fadedDarkPurple: "#524874",
  darkPurple: "#691a9f",
  radarGrey: "#838383",
  navPath: "#f7f6f9",
  pinkMenuHover: "#fdf2fb",

  // menu
  menuHeader: "#322e33",

  oldSecondary: "var(--color-neutral-d2)",

  buttonText: "var(--color-neutral-d2)",

  insightsItems: "#865fa3",

  midPurple: "var(--color-secondary-d4)",

  hasGuestsTrue: baseColours.positive,
  hasGuestsFalse: "var(--color-neutral-l2)",
};

// TODO: Fix double export
const allColours = {
  ...baseColours,
  ...variationColours,
  ...deprecatedColours,
  ...newBaseColours,

  claimed: "#cb36b5",

  reviewBubbleBackground: "#f0efff",
  reviewBubbleBackgroundHighlight: "#f6f5fd",
  directRecommendationBackground: "var(--color-neutral-l1)",

  highlight: "#fff200",
  stars: {
    1: "var(--color-rating-1)",
    2: "var(--color-rating-2)",
    3: "var(--color-rating-3)",
    4: "var(--color-rating-4)",
    5: "var(--color-rating-5)",
    empty: "#dcdfe5",
    filled: "var(--color-primary-d5)",
    estimated: "rgba(0,0,0,0.2)",
    lighter: {
      1: "#e66768", // TODO: use rateColours above (which is used by utils/colours.js)
      2: "#e6a86c",
      3: "#bebebe",
      4: "#e6de6c",
      5: "#c8e67b",
    },
  },

  roles: {
    default: "#7f4dce",
    host: "#d842e1",
    guest: "#8176d1",
    characterName: "rgba(129, 118, 209, .8)",
  },

  // creator wizard
  darkBlue: "#364259",
  deeperGrey: "#404957",

  // Pro Lead Form
  lightPurple: "#e6e0e9",

  // menu
  menuHeader: "#322e33",

  // leaderboard awards
  awardGold: "#fff71a",
  awardGoldBorder: "#ffce0c",
  awardSilver: "#d6dded",
  awardSilverBorder: "#a6c2db",
  awardBronze: "#f0ccaf",
  awardBronzeBorder: "#d38c60",

  /* Cards */
  cardSubtitleText: "var(--color-neutral-d6)",

  // Category Capsules
  cardCategoryHoverText: "#495466",

  // entity page sidebar
  entitySidebarText: "#6B7790",
  entitySubtleText: "#e0c7ea",

  // Badges
  badgeHighlight: "#fcdb00",

  // Integrations
  integrationStatusNotIntegratedLight: "var(--color-negative-d9)", // "#f0a8a8",
  integrationStatusNotIntegratedDark: "var(--color-negative-d3)", // "#ad1f1f",

  integrationStatusIntegratedLight: "var(--color-positive-d8)", // "#cae87d",
  integrationStatusIntegratedDark: "var(--color-positive-d3)", // "#607a1f",

  integrationStatusInProgressLight: "#facc9e",
  integrationStatusInProgressDark: "#ad661f",

  integrationStatusPartialLight: "var(--color-positive-d8)",
  integrationStatusPartialDark: "var(--color-positive-d3)",

  // Part of new colour system
  secondaryD9: "#E9CCFF",
  secondaryD10: "#090920",
  secondaryD15: "linear-gradient(90deg, #2C0074 0%, #2C0052 51%, #34006E 100%)",
  mainHeaderBackground: "#570f8a",

  // social
  twitterBlue: "#32c5ff",

  checkboxBackground: "var(--color-neutral-d9)",

  //BrandSafety
  yellow: "#ffe500",
  orange: "#F57E00",
  nuetralD2: "#332E38",
  nuetralD7: "#B3ABBA",
  scaleBackground: "#E6E1EA",

  actionedBoxShadow: "var(--box-shadow-actioned)",
  actionedSubtleBoxShadow: "var(--box-shadow-actioned-subtle)",
  actionedHighlight: "var(--color-actioned-highlight)",
  actionedLowlight: "var(--color-actioned-lowlight)",
  actionedForeground: "#fff", // text on top of actioned button

  //Socials
  facebook: "#4e70a8",
  youtube: "#FF0000",
  twitter: "#000",
  twitterBlueColor: "#47ACDF",
  twitterHover: "#515151",
  linkedIn: "#0762C8",
  twitch: "#6441a5",

  //Table
  outsideBorder: "#e6e1ea",
  insideBorder: "#f0edf2",
  header: "#807788",
  textGrey: "#9f9ea0",
  red: "#f70567",
  rowSelected: "#eef6ff",
  hasGuestsSelected: "#A1BDF7",
  selectedD95: "#E7EFFD",
  greyHover: "#f7f7f7",
  closeHover: "#edeaea",
  checkboxUnselected: "#e6e1ea",
  checkboxSelected: "#417def",

  //Aircheck
  darkGreyBackground: "#d4ceda",
  lightGreyBackground: "#e6e1ea",
  track: "#ffcce0",
  noneAdTrack: "#ccc",
  greySelected: "#E3EEFF",
  brightYellow: "#FFF200",
  brightOrange: "#FFD000",
  dynamicLightPurple: "#F0C9FF",

  // Top Search
  richPurple: "#55109b",
  lightLavender: "#ca96ff",
  violet: "#44047c",

  //Userlist
  defaultbutton: "#9b40bf",
  highlightPurple: "#d99cfc",
  defaultInset: "#6C2791",

  //Ads
  lightPink: "var(--color-secondary-d10)",
  pill: "var(--color-secondary-d6)",
  verifiedGrey: "var(--color-neutral-d6)",
  titleGradient:
    "linear-gradient(90deg, #6801BD 0%, #A003ED 55%, #C600FF 100%)",
  section2Gradient:
    "linear-gradient(90deg, #590293 0%, #67029F 55%, #7304A7 100%)",
  fadedPurple: "#9e87bc",
  selectedBlue: "dodgerBlue",

  //Demographics Insights
  dataVisPrimary: "#FF1B7B",
  dataVisSecondary: "#E84C8D",
  dataVisTertiary: "#C57396",
  dataVisQuaternary: "#C29BC1",
  dataVisQuinary: "#CCC1D9",
  // Demographics insights loaded bar
  darkPurple: "#7D1AB7",
  darkPurpleForeground: "#F5E9FC",
  brightBlue: "#0AA9C8",
  brightPink: "#CB36B5",
  darkishBlue: "#427CF0",
  veryLightBlue: "#CCDDFF",
  darkishBlueForeground: "#E2EBFF",
  fadedLightPurple: "#FDE8F9",
  fadedLightBlue: "#D2F5F9",
  brightLightGreen: "#1EC892",
  darkOrangeYellow: "#F8901F",
  boldPink: "#FA0567",
  darkGrey: "#696071",

  notice: "#f8901f",
  InsightCardBoxShadow:
    "0 2px 4px rgb(154 160 185 / 7%), 0 6px 11px rgb(166 173 201 / 20%)",

  //BarChart
  barGreen: "var(--color-positive-d5)",

  // Charts

  tickerUp: "#20c892",
  tickerDown: "#ef4342",

  // Contacts
  highConfidence: "#1EC892",
  moderateConfidence: "#F8901F",
};

export default allColours;
