import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useState } from "react";

import { selectSimpleForm } from "selectors/forms";

import useReduxState from "hooks/useReduxState";
import useScrollIntoViewOnFocus from "hooks/useScrollIntoViewOnFocus";
import { useStyles } from "hooks/useStyles";

import FieldStyles from "styles/FieldStyles";

const baseStyles = {
  ...FieldStyles,
  inputFieldContainer: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  inputContainer: {},
};

const BasicField = (props) => {
  const {
    dataId,
    label,
    helpText,
    renderInput,
    justInput,
    form,
    onFocus: passedOnFocus,
    handleBlur,
    handleFocus,
    handleChange: passedHandleChange,
    type,
    typeComponent,
    placeholder,
    disabled,
    inputContent,
    renderInputContent,
    belowInputContent,
    fieldProps: passedFieldProps,
    showFocusBar,
    showValidationBorder,
    field,
    required,
    extraChildren,
    extraAfterLabel,
    underInput,
    condition,
    optional,
    inputType: passedInputType,
    hideError,
    ignoreTouched,
    intersectingRef,
    autoComplete,
    isFirstFieldWithError,
    showInputRequiredStar,
    formKey,
    values: passedValues,
    errors: passedErrors,
    touched: passedTouched,
    disableScrollTo,
  } = props;

  const { name, value, onBlur: passedOnBlur, onChange } = field || props;

  const { setFieldValue, setFieldTouched } = form || {};

  const { error, touched } = useReduxState(
    (state) => {
      let fieldError = undefined;
      let fieldTouched = undefined;

      const simpleForm = selectSimpleForm(state, formKey);

      if (simpleForm) {
        fieldError = simpleForm.getIn(["errors", name]);
        fieldTouched = simpleForm.getIn(["touched", name]);
      }

      if (passedErrors) {
        fieldError = passedErrors[name] || fieldError;
      }
      if (passedTouched) {
        fieldTouched = passedTouched[name] || fieldTouched;
      }

      if (form) {
        if (form.errors) {
          fieldError = form.errors[name] || fieldError;
        }
        if (form.touched) {
          fieldTouched = form.touched[name] || fieldTouched;
        }
      }

      return {
        error: fieldError,
        touched: fieldTouched || ignoreTouched,
      };
    },
    [
      name,
      form,
      formKey,
      passedValues,
      passedErrors,
      passedTouched,
      ignoreTouched,
    ]
  );

  const onBlur = useCallback(
    (e) => {
      if (passedOnBlur) {
        passedOnBlur(e);
      }
      if (handleBlur) {
        handleBlur(e, field, form);
      }

      setHasFocus(false);
    },
    [field, form, handleBlur, passedOnBlur]
  );

  const onFocus = useCallback(
    (e) => {
      if (passedOnFocus) {
        passedOnFocus(e);
      }

      if (handleFocus) {
        handleFocus(e, field, form);
      }

      setHasFocus(true);
    },
    [field, form, handleFocus, passedOnFocus]
  );

  const handleChange = useCallback(
    (e) => {
      if (passedHandleChange) {
        passedHandleChange(e, field, form);
      }
      if (onChange) {
        onChange(e);
      }
    },
    [onChange, passedHandleChange, field, form]
  );

  const [hasFocus, setHasFocus] = useState(false);
  const scrollToRef = useScrollIntoViewOnFocus({
    focused: hasFocus,
    intersectingRef,
    forceScrollTo: isFirstFieldWithError,
    disabled: disableScrollTo,
  });
  const { styles } = useStyles(baseStyles, props);

  const shouldRender = useMemo(
    () => !condition || condition(form ? form.values : {}),
    [condition, form]
  );
  const InputType = typeComponent || type || "input";
  const inputIsString = typeof InputType === "string";
  // const hasValue = value != null && value !== '';

  const fieldProps = useMemo(() => {
    const fielProps = { ...passedFieldProps };

    if (inputIsString) {
      delete fielProps.intersectingRef;
    }

    return fielProps;
  }, [inputIsString, passedFieldProps]);

  const renderFieldInput = () => (
    <div ref={scrollToRef} className={css(styles.inputFieldContainer)}>
      {inputContent}
      {renderInputContent &&
        renderInputContent({
          setFieldValue,
          setFieldTouched,
        })}
      <InputType
        id={name}
        autoComplete={autoComplete}
        data-id={dataId}
        key={name}
        type={passedInputType || type}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value || ""} // cant pass in null or react gets grumpy
        placeholder={placeholder}
        disabled={disabled}
        className={css(
          styles.inputField,
          showValidationBorder && error && styles.inputError,
          showFocusBar && hasFocus && styles.inputFieldWithFocusBar,
          disabled && styles.inputFieldDisabled
        )}
        {...(InputType === "input" || InputType === "password" ? {} : { form })}
        {...fieldProps}
      />
      {showFocusBar && (
        <span
          className={css(
            styles.focusBar,
            touched && error && styles.errorFocusBar,
            touched && !error && styles.validatedFocusBar,
            !hasFocus && styles.focusBarClosed
          )}
        />
      )}
      {belowInputContent}
    </div>
  );

  if (!shouldRender) {
    return null;
  }

  if (justInput) {
    return renderFieldInput();
  }

  return (
    <div
      className={css(styles.basicField)}
      data-id={dataId ? `${dataId}-container` : null}
    >
      {label && (
        <label
          htmlFor={name}
          className={css(
            styles.inputLabel,
            styles.inputLabelText,
            optional && styles.inputLabelTextOptional
          )}
        >
          {typeof label === "function" ? label(styles, props) : label}
          {required && <div className={css(styles.inputRequired)} />}
          {optional && (
            <span className={css(styles.optionalLabel)}>Optional</span>
          )}
        </label>
      )}
      {typeof extraAfterLabel === "function"
        ? extraAfterLabel(props, styles)
        : extraAfterLabel}
      <div className={css(styles.inputContainer)}>
        {showInputRequiredStar && (
          <div className={css(styles.inputRequiredStar)}>*</div>
        )}
        {renderInput ? renderInput(renderFieldInput()) : renderFieldInput()}
        {underInput && (
          <div className={css(styles.underInput)}>
            {!hideError && error && (
              <div
                data-id="basic-field-error"
                className={css(styles.errorMessage)}
              >
                <div className={css(styles.errorMessageArrow)} />
                {error}
              </div>
            )}
            {helpText && (
              <div className={css(styles.inputInfo)}>{helpText}</div>
            )}
          </div>
        )}
      </div>
      {!underInput && (
        <div className={css(styles.bottom)}>
          {!hideError && error && (
            <div
              data-id="basic-field-error"
              className={css(styles.errorMessage)}
            >
              <div className={css(styles.errorMessageArrow)} />
              {error}
            </div>
          )}
          {helpText && <div className={css(styles.inputInfo)}>{helpText}</div>}
        </div>
      )}
      {typeof extraChildren === "function"
        ? extraChildren(props, styles)
        : extraChildren}
    </div>
  );
};

BasicField.propTypes = {
  formKey: PropTypes.string,
  form: PropTypes.object,
  type: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.any,
  helpText: PropTypes.node,
  inputContent: PropTypes.node,
  renderInputContent: PropTypes.func,
  belowInputContent: PropTypes.node,
  fieldProps: PropTypes.object,
  showFocusBar: PropTypes.bool,
  showValidationBorder: PropTypes.bool,
  justInput: PropTypes.bool,
  value: PropTypes.any,
  error: PropTypes.string,
  touched: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  name: PropTypes.string,
  renderInput: PropTypes.func,
  onChange: PropTypes.func,
  typeComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.string,
    PropTypes.object,
  ]),
  dataId: PropTypes.string,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  handleChange: PropTypes.func,
  field: PropTypes.object,
  required: PropTypes.bool,
  extraChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  extraAfterLabel: PropTypes.node,
  underInput: PropTypes.bool,
  optional: PropTypes.bool,
  inputType: PropTypes.string,
  hideError: PropTypes.bool,
  ignoreTouched: PropTypes.bool,
  intersectingRef: PropTypes.object,
  autoComplete: PropTypes.string,
  isFirstFieldWithError: PropTypes.bool,
  condition: PropTypes.func,
  showInputRequiredStar: PropTypes.bool,
  disableScrollTo: PropTypes.bool,
};

BasicField.defaultProps = {
  formKey: null,
  type: null,
  form: null,
  onFocus: null,
  onBlur: null,
  placeholder: null,
  disabled: false,
  label: null,
  helpText: null,
  inputContent: null,
  renderInputContent: null,
  belowInputContent: null,
  fieldProps: {},
  showFocusBar: false,
  showValidationBorder: false,
  justInput: false,
  value: null,
  error: null,
  touched: null,
  values: null,
  errors: null,
  name: null,
  renderInput: null,
  onChange: null,
  typeComponent: null,
  dataId: null,
  handleBlur: null,
  handleFocus: null,
  handleChange: null,
  field: null,
  required: false,
  extraChildren: null,
  extraAfterLabel: null,
  underInput: true,
  optional: false,
  inputType: null,
  hideError: false,
  ignoreTouched: false,
  intersectingRef: null,
  autoComplete: undefined,
  isFirstFieldWithError: false,
  condition: null,
  showInputRequiredStar: false,
  disableScrollTo: false,
};

export default memo(BasicField);
