import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import Col from "components/Common/Col";
import TopSearchContainer from "components/Search/TopSearchContainerAsync";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import HeaderStyles from "styles/HeaderStyles";

const HeaderSearch = (props) => {
  const {
    hideSearchToggle,
    onCancelSearch,
    onFocus,
    onSearchChange,
    onSearchCloseClick,
    searchBarBackgroundColor,
    searchHref,
    showSearch,
    showTip,
    switchToWhiteStyles,
    placeholder,
    searchAutocompleteStyles,
  } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("medium");

  const { styles } = useStyles(HeaderStyles, props);

  return (
    <Col
      className={css(
        styles.headerSearch,
        hideSearchToggle && styles.hideHeaderSearch,
        showSearch && styles.showHeaderSearch
      )}
    >
      <TopSearchContainer
        className={css(styles.headerSearchField)}
        onCancelSearch={onCancelSearch}
        onCloseClick={onSearchCloseClick}
        onFocus={onFocus}
        onSearchChange={onSearchChange}
        placeholder={placeholder}
        searchAutocompleteStyles={searchAutocompleteStyles}
        searchBarBackgroundColor={searchBarBackgroundColor}
        searchHref={searchHref}
        searchKey="top_search"
        showCloseWhenEmpty={isMediumOrLess}
        showCloseWhenTextEntered
        showSearch={showSearch}
        showTip={showTip}
        showTypeSelect
        switchToWhiteStyles={switchToWhiteStyles}
        white
      />
    </Col>
  );
};

HeaderSearch.propTypes = {
  hideSearchToggle: PropTypes.bool,
  onCancelSearch: PropTypes.func,
  onFocus: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSearchCloseClick: PropTypes.func,
  placeholder: PropTypes.string,
  searchBarBackgroundColor: PropTypes.string,
  showSearch: PropTypes.bool,
  switchToWhiteStyles: PropTypes.bool,
  showTip: PropTypes.bool,
  searchAutocompleteStyles: PropTypes.object,
};

HeaderSearch.defaultProps = {
  hideSearchToggle: false,
  searchBarBackgroundColor: null,
  showSearch: false,
};

export default memo(HeaderSearch);
