import { css, StyleSheet } from "aphrodite";
import merge from "ramda/src/mergeDeepRight";
import { useMemo } from "react";

import castArray from "utils/arrays/castArray";

import useStyleFlush from "hooks/useStyleFlush";

export const useMergedStyles = (styles, props = { styles: {} }) => {
  const [mergedStyles, doStyleFlush] = useMemo(() => {
    const mergedStyles = [
      ...castArray(styles).filter((f) => !!f),
      ...castArray(props?.styles).filter((f) => !!f),
    ].reduce((agg, next) => merge(agg, next), {});

    const hasTransition = Object.values(mergedStyles).some((obj) =>
      Object.keys(obj).includes("transition")
    );

    return [mergedStyles, hasTransition];
  }, [props, styles]);

  return {
    mergedStyles,
    doStyleFlush,
  };
};

export const useStyles = (styles, props = { styles: {} }) => {
  const { mergedStyles, doStyleFlush } = useMergedStyles(styles, props);

  const calcStyles = useMemo(
    () => StyleSheet.create(mergedStyles),
    [mergedStyles]
  );

  useStyleFlush(doStyleFlush);

  return { styles: calcStyles, css };
};

export const useForwardStyles = (baseStyles, { styles: passedStyles }) =>
  useMemo(() => {
    if (passedStyles) {
      return [baseStyles, passedStyles];
    }

    return baseStyles;
  }, [baseStyles, passedStyles]);
