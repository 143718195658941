import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import { Switch } from "react-router-dom";

import TopLevelRoute from "pages/Common/TopLevelRoute";
import Error404 from "pages/Error404";

import ChartsHubPage from "../../ChartsHub/async";
import Charts from "./async";

function ChartsRoutes() {
  return (
    <Switch>
      <TopLevelRoute
        path="/charts"
        exact
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <ChartsHubPage />
      </TopLevelRoute>
      <TopLevelRoute
        path="/charts/:platformId(apple|spotify)/:countryId?/:categoryId?"
        exact
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <Charts />
      </TopLevelRoute>

      <TopLevelRoute path="*" transparent={false}>
        <Error404 />
      </TopLevelRoute>
    </Switch>
  );
}

export default ChartsRoutes;
