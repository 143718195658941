import btoa from "btoa";
import qs from "qs";
import { useCallback, useEffect, useRef } from "react";

import {
  getStoredProgress,
  deleteOldEpisodesProgress,
} from "./storedProgressUtils";

import { useLocalStorage } from "hooks/useLocalStorage";
import useLoggedInUser from "hooks/useLoggedInUser";

const userProgress = "";
const LOCAL_PROGRESS_KEY = "gr7esdfs434"; // random string so its not obvious whats being stored

/*
Episode progress will only start being stored after the first 30 seconds.
When a previously stored episode is restarted, the episode begins 10 seconds earlier.
We put a limit on the number of episodes that can be stored (currently 1000),
when that limit is exceeded the oldest progress states get removed.

The episode progress states are stored in 3 different ways:
- loggedOutLocal - local state where all logged out user's progress's get stored
- userLocal - also local state, but this is only used when a user is logged in, when they
logout it gets deleted (CURRENTLY DISABLED UNTIL ENDPOINTS ARE ADDED).
If the general loggedOutLocal storage has stored progress when the user logs in,
they'll be moved into the userLocal state.
- userProgress - stored to the user's podchaser account(CURRENTLY DISABLED UNTIL ENDPOINTS ARE ADDED)

Episode progress is stored to the local state every 10 seconds and to the user's account every 30
seconds (to prevent to many api calls).

When stored locally progress objects are stringified and then encoded. This is to prevent other
people snooping through the episode's someone else has listened to
*/

// this will update every 30 seconds
const USER_UPDATE_PERIOD = 30;
//  this will update every 3 seconds
const LOCAL_UPDATE_PERIOD = 3;

export default function useStoredPlayerProgress(episode, episodePlayingID) {
  const loggedInUser = useLoggedInUser();
  const isLoggedIn = !!loggedInUser;

  const lastLoggedInUserIdRef = useRef(
    loggedInUser ? loggedInUser.get("id") : null
  );
  const USER_LOCAL_PROGRESS_KEY = `gr7esdfs4345${
    loggedInUser ? loggedInUser.get("id") : ""
  }`;

  const [
    userLocalProgress,
    setUserLocalProgress,
    // deleteUserStorage
  ] = useLocalStorage(USER_LOCAL_PROGRESS_KEY);
  const [
    loggedOutLocalProgress,
    setLoggedOutLocalProgress,
    // deleteLocalState
  ] = useLocalStorage(LOCAL_PROGRESS_KEY);

  const setLocalProgress = lastLoggedInUserIdRef.current
    ? setUserLocalProgress
    : setLoggedOutLocalProgress;
  const lastLocalUpdateRef = useRef(0);
  const lastUserUpdateRef = useRef(0);

  // NUMBER of seconds to 95%
  const episodeLengthOn95Percent = episode && episode.get("length") * 0.95;

  useEffect(() => {
    if (!loggedInUser && lastLoggedInUserIdRef.current) {
      /*
      once we add end points to store progress to a users account the deleteLocalState
      will clear out the locally stored progress when the user logs out

      deleteLocalState();
      deleteUserStorage();
      */
      lastLoggedInUserIdRef.current = null;
    }
    if (
      loggedInUser &&
      lastLoggedInUserIdRef.current !== loggedInUser.get("id")
    ) {
      lastLoggedInUserIdRef.current = loggedInUser.get("id");
    }
  }, [loggedInUser]);

  const getStoredEpisodeProgress = useCallback(
    (episodePlayingID) => {
      const progress = getStoredProgress({
        loggedOutLocalProgress,
        userLocalProgress,
        userProgress,
        isLoggedIn,
      });

      const episodeState = progress[episodePlayingID];
      const episodeProgress = episodeState
        ? episodeState.progress
        : episodeState;
      const newEpisodePreviousProgress = episodeProgress
        ? parseFloat(episodeProgress, 10)
        : null;

      lastLocalUpdateRef.current = newEpisodePreviousProgress || 0;
      lastUserUpdateRef.current = newEpisodePreviousProgress || 0;

      // rewinds the episode by 10 seconds when it starts
      return newEpisodePreviousProgress > 10
        ? newEpisodePreviousProgress - 10
        : newEpisodePreviousProgress;
    },
    [isLoggedIn, loggedOutLocalProgress, userLocalProgress]
  );

  const storeLocalProgress = useCallback(
    (playedSeconds) => {
      let progress = getStoredProgress({
        loggedOutLocalProgress,
        userLocalProgress,
        userProgress,
        isLoggedIn,
      });

      // if progress is under 95% store it && over 30 seconds (savePeriod) save,
      // else delete the episode's stored progress
      if (playedSeconds > 30 && playedSeconds < episodeLengthOn95Percent) {
        progress[episodePlayingID] = {
          progress: playedSeconds,
          date: `${Date.now()}`,
        };
      } else {
        delete progress[episodePlayingID];
      }

      progress = deleteOldEpisodesProgress(progress);
      // encode the progress state before storing, so people can't snoop on the episodes
      // other users that have used that device have been listening to
      setLocalProgress(btoa(qs.stringify(progress)));
    },
    [
      isLoggedIn,
      loggedOutLocalProgress,
      userLocalProgress,
      episodeLengthOn95Percent,
      setLocalProgress,
      episodePlayingID,
    ]
  );

  const storeEpisodeProgress = useCallback(
    (playingProg, playerClosed = false) => {
      const { playedSeconds } = playingProg || {};

      const localDiff = Math.abs(playedSeconds - lastLocalUpdateRef.current);
      const userDiff = Math.abs(playedSeconds - lastUserUpdateRef.current);

      const shouldUpdateLocalProgress = localDiff > LOCAL_UPDATE_PERIOD;
      const shouldUpdateExternalProgress =
        isLoggedIn && userDiff > USER_UPDATE_PERIOD;

      if (shouldUpdateLocalProgress) {
        storeLocalProgress(playedSeconds);
        lastLocalUpdateRef.current = playedSeconds;
      }
      if (shouldUpdateExternalProgress) {
        lastUserUpdateRef.current = playedSeconds;
        // set external progress state
      }
      if (playerClosed) {
        storeLocalProgress(playedSeconds);
      }
      if (playerClosed || !playedSeconds) {
        // set external progress state
        lastLocalUpdateRef.current = 0;
        lastUserUpdateRef.current = 0;
      }
    },
    [isLoggedIn, storeLocalProgress]
  );

  return {
    getStoredEpisodeProgress,
    storeEpisodeProgress,
  };
}
