import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Charts = Loadable(() => import("./Charts"));

function ChartsAsyncLoading(props) {
  return (
    <LoadableLoading
      key="topLevelRouteLoadingOverlay"
      withPageHeader
      height={"136rem"}
    />
  );
}

const ChartsContainer = (props) => {
  return <Charts {...props} fallback={<ChartsAsyncLoading />} />;
};

export default ChartsContainer;
